import { defineStore } from 'pinia'

export const usePersistStore = defineStore('persist', {
  state: () => {
    return {
      loanPeriod: 90,
      loanAmount: 1000,
      customIdentifier: '',
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_content: '',
      utm_term: '',
      utm_parameters: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],
      utm_values: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
      },
    }
  },

  actions: {
    onChangeLoanPeriod(value) {
      this.loanPeriod = value
    },
    onChangeLoanAmount(value) {
      this.loanAmount = value
    },
    onUpdateFromSearchParams(clickId, utmParameters) {
      if (clickId) {
        this.customIdentifier = clickId
      }

      if (utmParameters) {
        this.utm_parameters.forEach((param) => {
          if (utmParameters.hasOwnProperty(param) && utmParameters[param]) {
            this[param] = utmParameters[param]
            this.utm_values = { ...this.utm_values, [param]: utmParameters[param] }
          }
        })
      }
    },
  },

  persist: true,
})
